.frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  padding: 0px 8px 0px 0px;
  position: relative;

  .description {
    color: var(--tokens-text-text-secondary);
    font-family: var(--body-small-font-family);
    font-size: var(--body-small-font-size);
    font-style: var(--body-small-font-style);
    font-weight: var(--body-small-font-weight);
    letter-spacing: var(--body-small-letter-spacing);
    line-height: var(--body-small-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .line {
    height: 20px;
    margin-right: -1px;
    object-fit: cover;
    position: relative;
    width: 1px;
  }
}