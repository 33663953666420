@import "_variables.scss";

.custom-table-data-row {
  padding: 1.5rem;
  border-bottom: 0.1rem solid $color-border;
  line-height: 1.7rem;
}
.custom-table-header {
  width: 3rem;
  border-radius: 0.8rem 0.8rem 0rem 0rem;
}

.custom-table-data-last-row {
  padding: 1.5rem;
  border-bottom: 0.1rem solid $color-nav-selected;
  line-height: 1.7rem;
}
.custom-table-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.fedwire-box-default-width {
  width: 60%;
}
.heading-styles-h3 {
  font-size: 2.4rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
.heading-styles-h4 {
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
.custom-table-tr-fedwire {
  height: 4.6rem;
  border-color: $color-border;
  border-top-left-radius: 0.8rem;
}
.fedwire-left-custom-table-tr {
  text-transform: none;
  width: 51.5rem;
  background-color: $color-toast-info-notification;
  border-top-left-radius: 0.8rem;
}

.fedwire-right-custom-table-tr {
  width: var(--chakra-sizes-xs);
  background-color: $color-toast-info-notification;
  border-top-right-radius: var(--chakra-space-3);
  .custom-table-right-header {
    opacity: 0;
  }
}
