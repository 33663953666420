@import "_variables.scss";

.FAST-information-tag {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  border-radius: $border-radius-medium;

  .tag {
    padding: 6px 10px;
    height: 32px;
    border-radius: $border-radius-large;
    gap: $gap-small;
    font-family: $font-family-sfmono;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    line-height: $line-height-small;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    color: inherit;
    background-color: inherit;
  }

  &.tag-completed .tag {
    color: $tag-completed-text-color;
    background-color: $tag-completed-bg-color;
  }

  &.tag-inprogress .tag {
    color: $tag-inprogress-text-color;
    background-color: $tag-inprogress-bg-color;
  }

  &.tag-notstarted .tag {
    color: $tag-notstarted-text-color;
    background-color: $tag-notstarted-bg-color;
  }
}