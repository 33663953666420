@import "_variables.scss";

.custom-table-data-row-us-specific {
  padding: 1.5rem;
  border-bottom: 1px solid $color-border;
  line-height: 1.7rem;
}
.custom-table-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

.app-form-field-container {
  .custom-table-header {
    width: var(--chakra-sizes-12);
    border-top-left-radius: var(--chakra-space-2);
  }
  .custom-table-tr {
    .custom-table-th-right {
      .custom-table-right-header {
        opacity: 0;
      }
    }
  }
}

.custom-table-data-last-row {
  padding: 1.5rem;
  border-bottom: 1px solid $color-nav-selected;
  line-height: 1.7rem;
}
.custom-table-th {
  text-transform: none;
  width: 515px;
  background-color: $color-bg-badge;
  border-top-left-radius: 8px;
}
.custom-table-tr {
  height: 46px;
  border-color: $color-border;
  border-top-left-radius: 5px;
}
.custom-table-th-right {
  width: 210px;
  background-color: $color-bg-badge;
  border-top-right-radius: 8px;
}
.financial-specification-hr {
  border: 1px solid $color-border;
  margin-bottom: 2rem;
}
.us-specific-box-default-width {
  width: 70%;
}
.heading-styles-h3 {
  font-size: 2.4rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
.date-box-width-us-specific {
  width: 48%;
}
.date-box-width-us-specific-irs {
  width: 48%;
  margin-bottom: 2.5rem;
}
.custom-table-data-row-us-specific {
  .custom-table-padding-splitButton {
    padding-top: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-6);
    float: right;
  }
  .custom-table-padding-appLabel {
    padding-top: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-6);
    padding-left: var(--chakra-space-10);
  }
}
.custom-table-data-last-row {
  .custom-table-padding-splitButton {
    padding-top: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-6);
    float: right;
  }
  .custom-table-padding-appLabel {
    padding-top: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-6);
    padding-left: var(--chakra-space-10);
  }
}
