@import "_variables.scss";

.badge-card-container {
  width: 100%;
  border-radius: $border-radius-large;
  box-shadow: $box-shadow-default;
  box-sizing: border-box;
  padding: var(--chakra-space-8);
  &.active,
  &:hover {
    border: 1px solid $color-link-hover-color;
  }

  .badge-card-stacked-items {
    display: grid;
    gap: var(--chakra-space-6-4);
    align-items: center;
    grid-template-columns: 190px 1fr;
    grid-template-rows: 1fr;
    
    .badge-card-image {
      background-size: cover;
      border-radius: 8px;
      width: var(--chakra-space-60);
      height: var(--chakra-space-60);
    }
  }

  .badge-card-title-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--chakra-space-8);
    flex-wrap: wrap;

    .badge-title {
      color: $color-link-hover-color;
      font-size: $font-size-l;
      font-weight: $font-weight-semi-bold;
      line-height: $line-height-xlarge;
      a:hover {
        text-decoration: none;
      }
    }

    .custom-rating {
      gap: var(--chakra-space-6);
      display: flex;
      flex-wrap: wrap;
    }
  }

  .badge-description {
    font-size: $font-size-reg;
    font-weight: $font-weight-regular;
    line-height: $line-height-medium;
    margin-bottom: var(--chakra-space-4);
    padding-right: var(--chakra-space-8);
  }

  .badge-tag-holder {
    display: flex;
    gap: var(--chakra-space-8);
    margin: var(--chakra-space-8) 0;
    flex-wrap: wrap;
    .tag-default {
      padding: var(--chakra-space-2) var(--chakra-space-4);
    }
    .tag-success,
    .tag-warning {
      padding: var(--chakra-space-2) var(--chakra-space-8);
    }
  }

  .badge-module-accordion {
    background-color: $color-toast-message-notification;
    min-height: 42px;
    width: calc(100% + var(--chakra-space-16));
    border-radius: 0 0 $border-radius-large $border-radius-large;
    position: relative;
    bottom: 0;
    margin: 0 -2rem -2rem -2rem;
    border-color: transparent;

    .badge-module-accordion-button {
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      line-height: $line-height-small;
      padding: var(--chakra-space-4);
      border-radius: 0 0 $border-radius-large $border-radius-large;
      &:hover {
        background-color: $color-toast-message-notification;
      }
      &.expanded {
        font-weight: $font-weight-semi-bold;
        color: $color-link-hover-color;
      }
    }

    .badge-module-accordion-icon {
      margin-left: var(--chakra-space-4);
      font-size: $font-size-l;
    }

    .badge-module-accordion-item {
      min-height: 96px;
      background-color: $color-default-white;
      border: 1px solid $color-border;
      border-radius: $border-radius-small;
      position: relative;
      padding: var(--chakra-space-10);
      margin: var(--chakra-space-8);

      .badge-module-accordion-item-title {
        font-size: $font-size-reg;
        font-weight: $font-weight-medium;
        line-height: $line-height-medium;
        color: $color-default-font-color;
      }

      .badge-module-accordion-item-duration {
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        line-height: $line-height-small;
        color: $color-font-card;
      }

      .completed-status-icon {
        color: $color-toast-success-highlight;
      }

      .start-icon {
        position: absolute;
        left: -12px;
        top: 35px;
      }

      .enrolled-status-icon {
        color: $color-stepper-border;
        background-color: $color-default-white;
        border-radius: 35px;
      }

      .badge-module-accordion-item-subcontainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: var(--chakra-space-4);
        .tag-default {
          padding: var(--chakra-space-2) var(--chakra-space-4);
        }
        .tag-success,
        .tag-warning {
          padding: var(--chakra-space-2) var(--chakra-space-8);
        }
      }
    }
  }
}
