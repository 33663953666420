@import "_variables.scss";

.grid-item {
  display: flex;
  flex-direction: column;
  padding: var(--chakra-space-12);
  gap: var(--chakra-space-12);

  .badge-divider {
    width: 288px;
    border: 1px solid $color-border;
  }
}
